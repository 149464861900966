"use client"

import { useState, useRef, useEffect } from "react"
import { FiSend, FiX, FiCopy } from "react-icons/fi"
import { FaFilePdf, FaFileWord, FaFileExcel, FaFile } from "react-icons/fa"
import ReactMarkdown from "react-markdown"
import { useSelector } from "react-redux"
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter"
import axios from "axios"
import { materialLight } from "react-syntax-highlighter/dist/esm/styles/prism"
import { demoURL } from "../../client"

export default function BloodAssistant() {
  const [messages, setMessages] = useState(() => {
    const savedMessages = localStorage.getItem("bloodAssistantMessages")
    return savedMessages ? JSON.parse(savedMessages) : []
  })
  const { userInfo } = useSelector((state) => state.auth)
  const [userID, setEditedProfile] = useState(userInfo._id)
  const [input, setInput] = useState("")
  const [showFilePopover, setShowFilePopover] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [fileType, setFileType] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [currentBotMessage, setCurrentBotMessage] = useState("")
  const messagesEndRef = useRef(null)
  const fileInputRef = useRef(null)
  const [threadId, setThreadId] = useState(null)
  const [inputDisabled, setInputDisabled] = useState(true)
  const [isFirstMessage, setIsFirstMessage] = useState(true)
  const [selectedModel,setSelectedModel]= useState("gpt-4o-mini")

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages, currentBotMessage])

  useEffect(() => {
    localStorage.setItem("bloodAssistantMessages", JSON.stringify(messages))
  }, [messages])

  const handleSend = async () => {
    if (input.trim() === "" && !selectedFile) return

    const newMessage = {
      id: Date.now(),
      text: input,
      sender: "user",
      file: selectedFile
        ? {
            name: selectedFile.name,
            type: fileType,
          }
        : null,
    }

    setMessages((prev) => [...prev, newMessage])
    setInput("")
    setIsLoading(true)
    setInputDisabled(true)

    const botMessageId = Date.now() + 1

    setMessages((prev) => [
      ...prev,
      {
        id: botMessageId,
        text: "Generating...",
        sender: "bot",
      },
    ])

    try {
      const data = new FormData()
      if (selectedFile) {
        data.append("file", selectedFile)
        data.append("userId", userID)
        data.append("model",selectedModel)
      }

      const response = await fetch(`${demoURL}/post/newAssistant`, {
        method: "POST",
        body: data,
      })

      if (!response.ok) {
        throw new Error("Failed to fetch response")
      }

      const result = await response.json()
      // Update the bot message with the response
      setMessages((prev) =>
        prev.map((msg) => (msg.id === botMessageId ? { ...msg, text: result.msg || "No response received." } : msg)),
      )
    } catch (error) {
      console.error("Error fetching response:", error)
      setMessages((prev) => [
        ...prev.filter((msg) => msg.id !== botMessageId),
        {
          id: Date.now() + 2,
          text: "Sorry, there was an error processing your request.",
          sender: "bot",
        },
      ])
    } finally {
      setIsLoading(false)
      setInputDisabled(false) // Enable input after response is complete
      setSelectedFile(null)
      setFileType(null)
      if (fileInputRef.current) {
        fileInputRef.current.value = ""
      }
    }
  }

  const handleTextSend = async () => {
    if (input.trim() === "") return

    const userMessage = input.trim()
    setInput("")
    setInputDisabled(true)
    setIsLoading(true)

    // Add user message to chat
    const newMessage = {
      id: Date.now(),
      text: userMessage,
      sender: "user",
    }
    setMessages((prev) => [...prev, newMessage])

    // Add temporary bot message
    const botMessageId = Date.now() + 1
    setMessages((prev) => [
      ...prev,
      {
        id: botMessageId,
        text: "Generating...",
        sender: "bot",
      },
    ])

    try {
      // 1. Send message to create/message API
      const messageData = {
        message: userMessage,
        userId: userID ? userID : "1111",
        model:selectedModel
      }

      const messageResponse = await fetch(`${demoURL}/post/newAssistant`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(messageData),
      })

      if (!messageResponse.ok) {
        throw new Error("Failed to create message")
      }

      const result = await messageResponse.json()

      if (result.success) {
        setMessages((prev) => prev.map((msg) => (msg.id === botMessageId ? { ...msg, text: result.msg } : msg)))
      }
    } catch (error) {
      console.error("Error in text message processing:", error)
      // Update the bot message with an error
      setMessages((prev) =>
        prev.map((msg) =>
          msg.id === botMessageId ? { ...msg, text: "Sorry, there was an error processing your request." } : msg,
        ),
      )
    } finally {
      setIsLoading(false)
      setInputDisabled(false)
    }
  }
  useEffect(() => {
    const OnpageLeave = async () => {
      try {
        const data = { userId: userID ? userID : "1111" }
        const res = await axios.post(`${demoURL}/post/clearConversations`, data)
        console.log("onLeave", res)
      } catch (e) {
        console.log("Error clearing conversations:", e)
      }
    }

    return () => {
      OnpageLeave()
    }
  }, [])
  const handleFileSelect = (type) => {
    setFileType(type)
    setShowFilePopover(false)

    if (fileInputRef.current) {
      switch (type) {
        case "pdf":
          fileInputRef.current.accept = ".pdf,application/pdf"
          break
        case "docx":
          fileInputRef.current.accept =
            ".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          break
        case "excel":
          fileInputRef.current.accept =
            ".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          break
        default:
          fileInputRef.current.accept = ""
      }
    }

    fileInputRef.current.click()
  }

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setSelectedFile(e.target.files[0])
    }
  }

  const removeSelectedFile = () => {
    setSelectedFile(null)
    setFileType(null)
    fileInputRef.current.value = ""
  }

  const getFileIcon = (type) => {
    switch (type) {
      case "pdf":
        return <FaFilePdf className="text-red-500" />
      case "docx":
        return <FaFileWord className="text-blue-500" />
      case "excel":
        return <FaFileExcel className="text-green-500" />
      default:
        return <FaFile className="text-gray-500" />
    }
  }

  const hideScrollbarStyle = {
    scrollbarWidth: "none",
    msOverflowStyle: "none",
  }

  const hideScrollbarClass = `
  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
`

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // Optional: Add a toast notification here if you have a toast system
        console.log("Text copied to clipboard")
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err)
      })
  }

  const clearChat = () => {
    setMessages([])
    localStorage.removeItem("bloodAssistantMessages")
  }

  return (
    <>
      <style>{hideScrollbarClass}</style>
      <div className="flex flex-col h-full overflow-y-auto bg-slate-900 text-white p-4" style={hideScrollbarStyle}>
        <div className="max-w-4xl w-full mx-auto flex flex-col flex-grow">
          <div className="flex justify-between mb-2">
          <div className="relative">
              <select
                value={selectedModel}
                onChange={(e) => setSelectedModel(e.target.value)}
                className="bg-slate-800 text-black border border-gray-700 rounded-md px-3 py-1 appearance-none cursor-pointer pr-8"
              >
                <option value="gpt-4o-mini">Aims-Mini</option>
                <option value="gpt-4o">Aims-Omini</option>
                <option value="gpt-4-turbo">Aims-Turbo</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-white">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
            <button
              onClick={clearChat}
              className="px-3 py-1 bg-red-600 hover:bg-red-700 text-white text-sm rounded-md transition-colors"
            >
              Clear Chat
            </button>
          </div>
          <div className="flex-grow overflow-y-auto mb-4 space-y-3 p-1 hide-scrollbar" style={hideScrollbarStyle}>
            {messages.map((message) => (
              <div key={message.id} className={`flex ${message.sender === "user" ? "justify-end" : "justify-start"}`}>
                <div
                  className={`max-w-[80%] rounded-lg p-2 ${
                    message.sender === "user" ? "bg-white text-black" : "bg-white"
                  }`}
                >
                  {message.sender === "bot" && (
                    <div className="flex justify-end mb-1">
                      <button
                        onClick={() => copyToClipboard(message.text)}
                        className="p-1 rounded-full hover:bg-gray-100 text-gray-500 hover:text-gray-700 transition-colors"
                        title="Copy text"
                      >
                        {message.text != "Generating..." && (
                          <div className="flex justify-center items-center space-x-2">
                            <span className="text-black">copy</span>
                            <FiCopy className="text-black" size={14} />
                          </div>
                        )}
                      </button>
                    </div>
                  )}
                  <p>
                    <ReactMarkdown
                      className="text-black"
                      children={message.text}
                      components={{
                        h1: ({ node, ...props }) => (
                          <h1 style={{ fontSize: "1.75rem", marginBottom: "0.5rem", color: "#000000" }} {...props} />
                        ),
                        h2: ({ node, ...props }) => (
                          <h2 style={{ fontSize: "1.25rem", marginBottom: "0.25rem", color: "#000000" }} {...props} />
                        ),
                        h3: ({ node, ...props }) => (
                          <h3 style={{ fontSize: "1rem", marginBottom: "0.25rem", color: "#000000" }} {...props} />
                        ),
                        p: ({ node, ...props }) => (
                          <p style={{ marginBottom: "0.5rem", lineHeight: "1.4", color: "#000000" }} {...props} />
                        ),
                        ul: ({ node, ...props }) => (
                          <ul style={{ marginLeft: "1rem", marginBottom: "0.5rem", color: "#000000" }} {...props} />
                        ),
                        ol: ({ node, ...props }) => (
                          <ol style={{ marginLeft: "1rem", marginBottom: "0.5rem", color: "#000000" }} {...props} />
                        ),
                        li: ({ node, ...props }) => (
                          <li style={{ marginBottom: "0.25rem", color: "#000000" }} {...props} />
                        ),
                        code({ node, inline, className, children, ...props }) {
                          const match = /language-(\w+)/.exec(className || "")
                          return !inline && match ? (
                            <SyntaxHighlighter style={materialLight} language={match[1]} PreTag="div" {...props}>
                              {String(children).replace(/\n$/, "")}
                            </SyntaxHighlighter>
                          ) : (
                            <code
                              style={{
                                backgroundColor: "#f0f0f0",
                                color: "#000000",
                                padding: "1px 3px",
                                borderRadius: "4px",
                              }}
                              {...props}
                            >
                              {children}
                            </code>
                          )
                        },
                      }}
                    />
                    {/* {message.text} */}
                  </p>
                  {message.isStreaming && (
                    <span className="inline-block w-2 h-2 bg-white rounded-full animate-pulse ml-1"></span>
                  )}
                  {message.file && (
                    <div className="mt-2 flex items-center p-2 bg-opacity-20 rounded">
                      {getFileIcon(message.file.type)}
                      <span className="ml-2 text-sm">{message.file.name}</span>
                    </div>
                  )}
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
        </div>
      </div>
      <div className="px-4">
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          className="hidden"
          onDragOver={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          onDrop={(e) => {
            e.preventDefault()
            e.stopPropagation()
            if (e.dataTransfer.files && e.dataTransfer.files[0]) {
              const file = e.dataTransfer.files[0]
              const fileExt = file.name.split(".").pop().toLowerCase()

              if (fileExt === "pdf") {
                setFileType("pdf")
              } else if (["doc", "docx"].includes(fileExt)) {
                setFileType("docx")
              } else if (["xls", "xlsx"].includes(fileExt)) {
                setFileType("excel")
              } else {
                setFileType("other")
              }

              setSelectedFile(file)
            }
          }}
        />

        {messages.length === 0 ? (
          // When no messages exist, show only file picker
          <div
            className="flex flex-col items-center justify-center bg-[#111a33] rounded-lg p-4 border border-dashed border-gray-700 cursor-pointer hover:border-indigo-500 transition-colors"
            onClick={() => fileInputRef.current.click()}
          >
            <div className="flex mt-2 space-x-2">
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  handleFileSelect("pdf")
                }}
                className="flex items-center px-2.5 py-1 bg-[#1a2440]/80 rounded-md hover:bg-[#232f52] transition-colors border border-gray-700/30"
              >
                <FaFilePdf className="text-red-500 mr-1.5" size={12} />
                <span className="text-xs text-white font-medium">PDF</span>
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  handleFileSelect("docx")
                }}
                className="flex items-center px-2.5 py-1 bg-[#1a2440]/80 rounded-md hover:bg-[#232f52] transition-colors border border-gray-700/30"
              >
                <FaFileWord className="text-blue-500 mr-1.5" size={12} />
                <span className="text-xs text-white font-medium">Word</span>
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  handleFileSelect("excel")
                }}
                className="flex items-center px-2.5 py-1 bg-[#1a2440]/80 rounded-md hover:bg-[#232f52] transition-colors border border-gray-700/30"
              >
                <FaFileExcel className="text-green-500 mr-1.5" size={12} />
                <span className="text-xs text-white font-medium">Excel</span>
              </button>
            </div>
          </div>
        ) : (
          // When messages exist, show text input field
          <div className="mt-4 px-4">
            <div className="flex items-center rounded-md p-2 border border-gray-700/50 bg-gray-800/30">
              <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder={inputDisabled ? "Please wait..." : "Type your message here..."}
                className={`flex-grow bg-transparent text-white px-3 py-2 outline-none ${
                  inputDisabled ? "opacity-50" : ""
                }`}
                onKeyPress={(e) => e.key === "Enter" && !e.shiftKey && !inputDisabled && handleTextSend()}
                disabled={inputDisabled}
              />
              <button
                onClick={handleTextSend}
                className={`p-1 rounded-full bg-indigo-600/80 hover:bg-indigo-700 text-white transition-colors ${
                  isLoading || inputDisabled ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={isLoading || inputDisabled}
                title="Send message"
              >
                <FiSend size={16} />
              </button>
            </div>
          </div>
        )}

        {/* Show file preview when a file is selected (regardless of message count) */}
        {selectedFile && messages.length === 0 && (
          <div className="flex items-center justify-between rounded-md p-2 mt-2 border border-gray-700/50 bg-gray-800/30">
            <div className="flex items-center space-x-2">
              <div className="flex items-center mr-2">
                {getFileIcon(fileType)}
                <span className="ml-1.5 text-xs truncate max-w-[180px] text-white">{selectedFile.name}</span>
              </div>
              <button
                onClick={removeSelectedFile}
                className="p-1 rounded-full hover:bg-gray-700/50 text-gray-400 hover:text-white transition-colors"
                title="Remove file"
              >
                <FiX size={16} />
              </button>
              <button
                onClick={handleSend}
                className={`p-1 rounded-full bg-indigo-600/80 hover:bg-indigo-700 text-white transition-colors ${
                  isLoading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={isLoading}
                title="Send file"
              >
                <FiSend size={16} />
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

