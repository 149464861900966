import axios from "axios";
import React, { useState , useEffect } from "react";
import { TbReportSearch } from "react-icons/tb";
import { demoURL, Node_API_URL } from "../../client";
import { FaEdit } from "react-icons/fa";  
import { FiRefreshCw } from "react-icons/fi";

import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialLight } from "react-syntax-highlighter/dist/esm/styles/prism";
import { MdDeleteForever } from "react-icons/md";  
// import { assistant_test_server } from "../../client"; 
import { getUserInfo } from "../../features/auth/authSlice"; 
import { useSelector , useDispatch } from "react-redux"; 
import BloodAssistant from "../ConsultationOptions/BloodAssistant.js";
import Loader from "../ui/Loader/ButtonLoader";   
import TypingIndicator from "../Aims-assistant/TypingLoader";
import { FaDownload } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";   
import { useRef } from "react";
import { useContext } from "react";   
import OptionModal from '../ui/OptionModal/OptionModal.js';
import { GlobalStateContext } from "../../Context/GlobalStateContext";
import { SoapNotesPrompt } from "../prompts/soapNotes"; 

import { InitialExamintionPrompt } from "../prompts/InitialExamintion";
import { FollowUpPrompt } from "../prompts/FollowUp";
const ReportGenerator = ({saveReport,params, text , setText , option1 ,setReportdata , setTrans , demoText ,setopen }) => {
  const [selectedTab, setSelectedTab] = useState("SOAP Note"); 
  const {showToast , toastActive , token , userTimezone} = useContext(GlobalStateContext); 
  const { userInfo } = useSelector((state) => state.auth); 

  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [loading ,setLoading] = useState(false);  
  const [isOptionModal , setOptionModal] = useState(false);
  
  const [promptText, setPromptText] = useState(SoapNotesPrompt);
  const [selectedModel, setSelectedModel] = useState("gpt-4o");
  const [data,setData] = useState("")  
  const [report , setReport] = useState('');
  const textAreaRef = useRef(null);
   const [edit,setEdit] = useState(false)
  const [titles] = useState([
    "SOAP Note",
    "Initial Examination",
    "Follow-up Visit",
    "Re-Evaluation"
  ]) 
 
  const handleGenerateClick = () => { 
    
    if(!text){
      showToast('Enter consultation')
      return
    }
    setIsModalOpen(true);
  }; 
  const [AssistantId , setAssistantID] = useState('');
  useEffect(()=> { 
    const Transcription = localStorage.getItem('Transcription');  
    if (Transcription!=null) {  
      setText(Transcription)
    }
    return () => {
      localStorage.removeItem('Transcription');
    };
  },[]); 

  const handleModalClose = () => {
    setIsModalOpen(false);
  };  
  const [dateNow, setDate] = useState(null);

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toLocaleDateString('en-GB', {
      timeZone: userTimezone,
    });
    setDate(formattedDate);
  }, []);
  const handleGenerateNote = async () => { 
    setIsModalOpen(false);
    setLoading(true);  
    try{
      if(!promptText){
        showToast('Enter system prompt')
        return
      } 
      const data = {
        text: `Doctor Name :${userInfo.first_name} ${userInfo.last_name} Doctor Phono Number : ${userInfo.
phone_number} Doctor Title : ${userInfo.title} Date of Consultation : ${dateNow} Transcript : ${text}`,
        prompt:` ${promptText}` ,
        model:selectedModel
      } 
      const res = await axios.post(`${demoURL}/post/testingNewReportMethod`,data)
      setData(res.data.notes)    
      setIsModalOpen(false); 
      setReportdata(res.data.notes); 
      if (demoText) { 
        setTrans(false);
      }
      setReport(res.data.notes); 
      setLoading(false); 
      await handleAssistant(res.data.notes);
    }catch(e){
   console.log(e);
    } 
    finally { 
      setLoading(false); 
    }
  };  
  
  // const createAssistant = async (notes) => {
  //   let name = '78fyv8ydkfecjwe9cu093'; 
  //  try { 
  //  const res = await axios.post(`${demoURL}/create/assistant`,{data: notes ,name});  
  //  console.log(res);
  //   setAssistantID(res.data.assistant.id)
  //  } 
  //  catch(e){ 
  //  console.log(e);
  //  }
  // }

  const toggle = () =>{
    if(edit)
    {
      setEdit(false)
      return
    }
    setEdit(true)
  }
   const [assistant , setAssistant] = useState(false);  
   const [isOpen , setOpen] = useState(false); 
   const [changeText,setchangeText] = useState('');
   const [messages, setMessages] = useState([]);
   const [inputMessage, setInputMessage] = useState(''); 
   const [typing , setTyping] = useState(false);   
   const [disableInput , setDisableInput] = useState(false);
   const [thread , setReportThread] = useState(null); 
   const messagesEndRef = useRef(null); 
   const [updating , setUpdating] = useState(false);
   const handleRevise = async () => {   
    setUpdating(true); 
    try { 
       if (!thread) {
    const id = await ReportSend();  
    await reviseReport(id);   
       } 
       else { 
        await reviseReport(thread);
       }
    } 
    finally{ 
      setUpdating(false); 
      setOpen(false); 
      setchangeText(''); 
    }

   }
   const getRun =  async (id) => {  
     const runData = { 
       threadId:id,
       assistantId :'asst_Z7kalJHDtGK1Lt24iVa1buWJ',
     }
    try { 
      const run = await axios.post(`${demoURL}/create/run`,runData)
       if (run.data.response === true) { 
        return run.data.run.id;
       }
    } 
    catch (e) { 

    } 
   }    

  
   useEffect(()=> {
    return () => { 
      const thread = localStorage.getItem('ThreadID'); 
      if (thread) {
      localStorage.removeItem('ThreadID');   
      console.log('Thread Removed'); 
      }
    }
   },[]) 
     const [generator , setGenerator] = useState(true);
   
    const getResponse = async (threadID , runid) => {   

      const data = { 
        threadId : threadID, 
        runId: runid
      }
      try { 
      const res = await axios.post(`${demoURL}/get/runStatus`,data);  
        if (res.data.response === true ) { 
          return res.data.run.status;
        }  
        else {
        return res 
        }
      } 
      catch (e) { 

      }
    }
  
    
   const handleSendMessage = async (e) => {  
    
    setDisableInput(true);
     e.preventDefault();  
     if (inputMessage.trim() !== '') {
       const newMessage = {
         id: Date.now(),
         text: inputMessage,
         sender: 'user'
       }; 
       setInputMessage(''); 
      //  if (messages.length <= 0 ) { 
      //  const response = await axios.get(`${demoURL}/create/thread`);   
       
      //  if (response.data.response === true) { 
      //   localStorage.setItem('ThreadID',response.data.thread.id); 
      //   setReportThread(response.data.thread.id);
      //  }
      //  } 
       let idThread = localStorage.getItem('ThreadID'); 
       const data = { 
        message:inputMessage, 
        threadId:idThread,
       }
    
       setMessages(prevMessages => [
         ...prevMessages,  
         { sent: newMessage.text, reply: null }
        ]);      
       try { 
         const res = await axios.post(`${demoURL}/create/message`, data);  
         // console.log(res);
         if(res.data.response == true)  { 
            
            const final = await getRun(idThread); 
            
            let final2 = await getResponse(idThread , final); 
            while (final2 != 'completed' && final2 != 'cancelled' && final2 != 'failed' && final2 != 'expired') {  
              final2 = await getResponse(idThread , final); 
            }  
            setTyping(true); 
      const message = await axios.get(`${demoURL}/get/messages?threadId=${idThread}`); 
       const body = message.data.list.body; 
        const reply = body.data[0].content[0].text.value;
                setMessages(prevMessages => {
            const lastMessageIndex = prevMessages.length - 1;  
            return prevMessages.map((msg, index) => 
                index === lastMessageIndex 
                ? { ...msg, reply: reply } 
                : msg 
            );  
             
        });    
        if (final2 === 'cancelled' || final2 === 'failed' || final2 === 'expired') {
        const cancelData = { 
          runId: final, 
          threadId:idThread
        } 
      
        const cancelRun = await axios.post(`${demoURL}/cancel/cancelRun`,cancelData )  
        console.log(cancelRun); 
      }
}  
        } 
       
       catch (e){ 

       } 
       finally { 
        setTyping(false);  
        setDisableInput(false); 
        textAreaRef.current.focus();
       }
      
     }
   }; 
  //  const clearChat = () => { 
  //    const thread = localStorage.getItem('ThreadID') 
  //    if (thread) { 
  //     localStorage.removeItem('ThreadID'); 
  //     console.log('Thread Removed');
  //    }  
  //    setMessages([]);
  //    setThread(null); 
     
  //  } 
  
  const handleAssistant = async (notes) => {    
     await handleSendReport(notes);   
  }  
  const checkAssistant = async () => {   
    if (data) { 
      setAssistant(true);  
      if (demoText) { 
        setopen(true); 
        setGenerator(false); 
        setBloodAssistant(false);
      }   
      
    } 

    else if (!data) { 
      alert('No report Generated YET')
    }
  } 

   useEffect(() => {
     messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
   }, [messages]);  
   const handleSendReport = async (notes) => {
     let idThread; 
    try {
  
        const response = await axios.get(`${demoURL}/create/thread`); 
        console.log('Assistant Thread Created');
          idThread = response.data.thread.id;  
          localStorage.setItem('ThreadID', response.data.thread.id);
      
  
      const Data = {
        message: notes, 
        threadId: response.data.thread.id,
      };
  
      const res = await axios.post(`${demoURL}/create/message`, Data); 
    } catch (error) {
      console.error("Error in handleSendReport:", error);
    }
  };
  
  const handleDelete = () => {
    setData(""); 
    setReportdata('');  
    if(demoText) { 
      setTrans(true);
    }
    setReportThread('');
    localStorage.removeItem('ThreadID');
    setMessages([]);
  };
  
  const reviseReport = async (id) => {  
         
       const Data = { 
        message:`Change the existing Report by Following the Next Prompt and return the updated report full . 
        Prompt:${changeText} `, 
        threadId:id,
       }   
       try {  
       const res = await axios.post(`${demoURL}/create/message`, Data);    
       if(res.data.response == true)  { 
          
          const final = await getRun(id);  
          
          let final2 = await getResponse(id , final);  
          while (final2 != 'completed' && final2 != 'cancelled' && final2 != 'failed' && final2 != 'expired') {  
            final2 = await getResponse(id , final); 
            console.log('final2',final2);
          }  
    const message = await axios.get(`${demoURL}/get/messages?threadId=${id}`);  
     if (message) { 
      localStorage.removeItem('ThreadID');   
      await handleAssistant(message.data.list.body.data[0].content[0].text.value); 
     }
     const body = message.data.list.body; 
      const reply = body.data[0].content[0].text.value;  
      setData(reply); 
      
        }  
      }
     catch(e){ 

     }
  }   
  const ReportSend = async () => {  
    const response = await axios.get(`${demoURL}/create/thread`);    
    if (response) {
      setReportThread(response.data.thread.id);         
    }  
     const Data = { 
      message:report, 
      threadId:response.data.thread.id,
     }   
     try {  
     const res = await axios.post(`${demoURL}/create/message`, Data);     
      if (res) {
        return Data.threadId
      } 
     }
   catch(e){ 

   }
}   
  const assistantFalse = () => { 
    setAssistant(false);  
    setGenerator(true); 
    setBloodAssistant(false);
    if (demoText) { 
      setopen(false); 
    } 
    
  }
   const [borderColor , setBorderColor ] = useState(); 


   const convertToDocx = async () => {
    try {
      // Construct the payload for the POST request
      const payload = {
        unstructuredResponse:data, // Include the visitId in the payload
      };
  
      const result = await axios.post(
        `${Node_API_URL}/api/post/reportDocxDirectDownload`,
        payload, // Pass the payload in the body of the POST request
        {
          responseType: 'blob', // Important: Set response type to blob
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json", // Set appropriate content type
          },
        }
      );
  
      if (!result.data) {
        alert('Download process failed');
        return;
      }
  
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const a = document.createElement('a');
      a.href = url;
      a.download = `Report.docx`;
      document.body.appendChild(a);
      a.click();
  
      // Clean up
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  }; 
    const [bloodAssistant , setBloodAssistant] = useState(false);
        const handleBloodAssitant = () => { 
          setBloodAssistant(true); 
          setGenerator(false); 
          setAssistant(false);
        }
  
  return (
    <div className={` dark:bg-slate-900 w-full  flex flex-col   items-center justify-center p-6`}> 
        <ToastContainer />  
        {isOptionModal && 
     <OptionModal option1={()=>{setOptionModal(false) ;option1();}}  option2={()=>{ setOptionModal(false) ;handleGenerateClick();}} close={()=>setOptionModal(false)}/>
     }
        <div className="flex flex-col mb-6">
          <div className="flex gap-5"> 
            <div onClick={assistantFalse} className={`flex cursor-pointer ${!assistant && !bloodAssistant  ? 'bg-[#e89910]' : ''} items-center rounded-md p-2`}>
            <TbReportSearch className="text-white mr-2" size={25} />
            <h1 className="text-white text-sm md:text-xl font-bold  text-start">
              Medical Note Generator
            </h1>   
            </div> 
            <div  onClick={checkAssistant} className={`flex cursor-pointer ${assistant ? 'bg-[#e89910]' : ''} items-center rounded-md p-2`}>
            <TbReportSearch className="text-white mr-2" size={25} />
            <h1 className="text-white text-sm md:text-xl font-bold  text-start">
               Assistant
            </h1>   
            </div>    
           <div  onClick={handleBloodAssitant} className={`flex cursor-pointer ${bloodAssistant ? 'bg-[#e89910]' : ''} items-center rounded-md p-3`}>
             <TbReportSearch className="text-white mr-2" size={25} />
           <h1 className="text-white text-xl font-bold  text-start">
           Lab Interpretor
            </h1>   
                 </div> 
       
            {/* { assistant && messages.length > 0 &&
            <div onClick={clearChat} className={`flex cursor-pointer gap-1 bg-red-500 hover:bg-red-700 items-center rounded-md p-3`}>
             <h1 className="text-white text-xl font-bold  text-start">
               Clear Chat
            </h1>   
             <MdDeleteForever className="text-white" size={25} />
            </div>  
} */}
          </div>
          
          </div>
      <div className={`bg-slate-800 h-[550px] border-[1px] border-grey rounded-lg shadow-xl w-full  max-w-6xl     ${assistant ? 'p-0': 'p-8' }`}>
        {/* Header */}
       
          
          {assistant ? (
  <>
       <div style={{ width: '100%', height:'100%' }}>
      <div style={{ 
        height:'100%',
        overflowY: 'auto', 
        backgroundColor:'#1E293B',
        // border: '1px solid #ccc', 
        borderRadius:'12px', 
        padding: '50px',
      }}> 
       <style>
    {`
      /* Webkit Browsers */
      div::-webkit-scrollbar {
        width: 8px; /* Width of the scrollbar */
      }
      div::-webkit-scrollbar-thumb {
        background: #888; /* Color of the scrollbar thumb */
        border-radius: 10px; /* Rounded corners for the thumb */
      }
      div::-webkit-scrollbar-thumb:hover {
        background: #555; /* Darker color on hover */
      }
      div::-webkit-scrollbar-track {
        background: trasparent; /* Background color of the scrollbar track */
        border-radius: 10px; /* Rounded corners for the track */
      }
    `}
  </style>
  {messages && messages.length > 0 ? (
  messages.map((message, index) => (
    <div key={index}>
        <div  
        className="ml-auto max-w-[50%] mt-6"
        style={{
          background: message.sent ? '#007bff' : '#e9ecef', 
          marginLeft:'auto',
          color: message.sent ? 'white' : 'black',
          padding: '5px 10px',
          borderRadius: '10px', 
          display: 'block', 
          width: 'fit-content', 
          maxWidth: '50%', 
          wordBreak: 'break-word', 
        }}
      >
        {message.sent}
      </div> 
   
      {message.reply ? (
        <>
      <div
  className="mr-auto w-[90vw] md:max-w-[50%] mt-6"
  style={{
    background: message.reply ? '#e9ecef' : '#007bff',
    color: message.reply ? 'black' : 'white',
    padding: '5px 10px',
    borderRadius: '10px',
    display: 'block', 
    width: 'fit-content', 
    maxWidth: '50%', 
    wordBreak: 'break-word', 
  }}
>
      {/* { */}
        {/* message.reply &&  */}
      <ReactMarkdown
      className="text-black"
      children={message.reply}
      components={{
        h1: ({ node, ...props }) => (
          <h1 style={{ fontSize: "2rem", marginBottom: "1rem", color: "#050505" }} {...props} />
          ),
          h2: ({ node, ...props }) => (
            <h2 style={{ fontSize: "1.5rem", marginBottom: "0.75rem", color: "#050505" }} {...props} />
            ),
            h3: ({ node, ...props }) => (
              <h3 style={{ fontSize: "1.25rem", marginBottom: "0.5rem", color: "#050505" }} {...props} />
              ),
              p: ({ node, ...props }) => (
                <p style={{ marginBottom: "1rem", lineHeight: "1.5", color: "#050505" }} {...props} />
                ),
                ul: ({ node, ...props }) => (
          <ul style={{ marginLeft: "1.5rem", marginBottom: "1rem", color: "#050505" }} {...props} />
          ),
          li: ({ node, ...props }) => (
            <li style={{ marginBottom: "0.5rem", color: "#050505" }} {...props} />
            ),
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || "");
              return !inline && match ? (
                <SyntaxHighlighter
                style={materialLight}
                language={match[1]}
                PreTag="div"
                {...props}
                >
              {String(children).replace(/\n$/, "")}
            </SyntaxHighlighter>
          ) : (
            <code style={{ backgroundColor: "#333", color: "#050505", padding: "2px 4px", borderRadius: "4px" }} {...props}>
              {children}
            </code>
          );
        },
      }}
      />
    {/* } */}
      </div>  
        </>
      ) : (
        <div className="mr-auto p-4 w-[35vw] rounded-xl text-gray-300 overflow-hidden">
          <TypingIndicator />
        </div>
      )}
    </div> 
  ))
) : (
  <p className="text-center text-white text-xl font-semibold">
    Your chat will be displayed here.
  </p>
)} 




        <div ref={messagesEndRef} />
      </div> 
 

      <form onSubmit={handleSendMessage} style={{ display: 'flex' }}>
      <textarea  
      ref={textAreaRef}
      className="dark:bg-slate-800 text-white" 
      disabled={disableInput}
  value={inputMessage}
  onKeyDown={(e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage(e);
    }
  }}
  onChange={(e) => {
    setInputMessage(e.target.value);
    e.target.style.height = '50px'; 
    e.target.style.height = `${Math.min(e.target.scrollHeight, 185)}px`; 
  }}
  
  style={{
    width: '100%',
    height: '50px',
    resize: 'none', 
    marginTop:'20px', 
    marginBottom:'60px',
    padding: '10px',
    fontSize: '16px',
    borderRadius: '25px',
    border: `1px solid ${borderColor}`,
    overflow: 'auto', 
  }}
  placeholder="Type your message here..." 
  onFocus={() => setBorderColor('#3B82F6')}
  onBlur={() => setBorderColor('#2262bd')}
/> 
<style>
    {`
      /* Webkit Browsers */
      textarea::-webkit-scrollbar {
        width: 8px; /* Width of the scrollbar */
      }
      textarea::-webkit-scrollbar-thumb {
        background: #888; /* Color of the scrollbar thumb */
        border-radius: 10px; /* Rounded corners for the thumb */
      }
      textarea::-webkit-scrollbar-thumb:hover {
        background: #555; /* Darker color on hover */
      }
      textarea::-webkit-scrollbar-track {
        background: trasparent; /* Background color of the scrollbar track */
        border-radius: 10px; /* Rounded corners for the track */
      }
    `}
  </style>

      </form>
        {/* <button 
          type="submit" 
          style={{
            padding:'10px', 
            width:'200px', 
            backgroundColor: '#007bff',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer'
          }}
        >
          Send
        </button> */}
    </div>
   </>
) 
  : bloodAssistant ? (
     <div style={{ width: '100%', height: '380px' }}> <BloodAssistant/> </div>
) :  generator && !assistant && ( 
  <> 
   
     <div className="flex flex-col ">
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
      {titles.map((tab) => (
        <button
          key={tab}
          onClick={() => {
            if(tab === 'SOAP Note')
              setPromptText(SoapNotesPrompt);
            else if(tab === 'Initial Examination')
              setPromptText(InitialExamintionPrompt);
            else if(tab === 'Follow-up Visit')
              setPromptText(FollowUpPrompt);
            else
              setPromptText('Write a detailed Re-Evaluation note based on the following patient details and consultation updates');
            setSelectedTab(tab);
          }}
          className={`px-6 py-3 rounded-lg text-white font-semibold transition-all duration-300 ${
            selectedTab === tab
              ? "bg-blue-500 shadow-md"
              : "bg-slate-600 hover:bg-slate-500"
          }`}
        >
          {tab}
        </button>
      ))}
    </div>

    {/* Text Editor */}
    <div className="mt-6">
      <textarea
        className="w-full h-48 dark:bg-slate-800 text-white mt-6 p-5 rounded-lg border border-slate-700 focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none shadow-sm"
        placeholder="Type your notes here..."
        value={text} 
        onChange={(e) => setText(e.target.value)}
      ></textarea>
    </div>

    {/* Footer Button */}
    <div className="flex justify-center mt-6">
      <button
        className="bg-green-500 w-full text-white px-8 py-3 rounded-lg font-semibold hover:bg-green-600 transition-all duration-300 shadow-md"
        onClick={()=>setOptionModal(true)}
        disabled={loading}
      > 
       {loading ? ( 
              <div className="flex justify-center">
              <Loader/>  
              </div>
            ) : ( 
 
              <p> Generate Report </p>
            )
            }
        
      </button>
    </div> 
    </div>
  </>
)}

      </div>

{
  data  &&  !assistant && !bloodAssistant &&

      <div className="bg-slate-800 border-[1px] border-grey rounded-lg w-full overflow-hidden mx-auto max-w-5xl p-8 mt-8 shadow-xl ">
        <div className=" flex justify-between flex-col md:flex-row "> 
          <div className="flex gap-4 items-center mb-8 ">  
        <FaEdit onClick={toggle}  size={20} className="text-green-500 cursor-pointer" />
          <span onClick={()=>setOpen(true)} className="bg-green-500 hover:bg-green-600 text-white cursor-pointer p-2  gap-3 flex items-center justify-center h-12 text-center rounded-md"> Revise Report 
          <FiRefreshCw size={15}/> 
            </span> 
          </div>
        <h1 className="text-center text-white text-4xl mb-8 underline font-bold" >Report</h1>  
        <div className="flex gap-4 items-center  mb-8 justify-center"> 
      
        <span onClick={convertToDocx}  className="bg-blue-500 hover:bg-blue-600 text-white cursor-pointer p-3  gap-3 flex items-center justify-center h-12 text-center rounded-md"> Download 
          <FaDownload size={15}/> 
            </span> 
        <MdDeleteForever size={25} className="text-red-500  cursor-pointer" onClick={handleDelete}  />
        </div>
        </div>
      {/* method 1 for display  */}
      {
        edit &&
        <textarea
        className="w-full h-96 p-3 bg-slate-800 text-black border border-gray-700 rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
        placeholder="Enter your prompt here..."
        value={data}
        onChange={(e) => setData(e.target.value)}
        cols={30}
        ></textarea>
    }
      
      {/* method 2 for display  */}
      {
        !edit && 
      <ReactMarkdown
      className="text-white"
      children={data}
      components={{
        h1: ({ node, ...props }) => (
          <h1 style={{ fontSize: "2rem", marginBottom: "1rem", color: "#FFD700" }} {...props} />
          ),
          h2: ({ node, ...props }) => (
            <h2 style={{ fontSize: "1.5rem", marginBottom: "0.75rem", color: "#FFA500" }} {...props} />
            ),
            h3: ({ node, ...props }) => (
              <h3 style={{ fontSize: "1.25rem", marginBottom: "0.5rem", color: "#FFA07A" }} {...props} />
              ),
              p: ({ node, ...props }) => (
                <p style={{ marginBottom: "1rem", lineHeight: "1.5", color: "#FFFFFF" }} {...props} />
                ),
                ul: ({ node, ...props }) => (
          <ul style={{ marginLeft: "1.5rem", marginBottom: "1rem", color: "#FFFFFF" }} {...props} />
          ),
          li: ({ node, ...props }) => (
            <li style={{ marginBottom: "0.5rem", color: "#FFFFFF" }} {...props} />
            ),
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || "");
              return !inline && match ? (
                <SyntaxHighlighter
                style={materialLight}
                language={match[1]}
                PreTag="div"
                {...props}
                >
              {String(children).replace(/\n$/, "")}
            </SyntaxHighlighter>
          ) : (
            <code style={{ backgroundColor: "#333", color: "#FFD700", padding: "2px 4px", borderRadius: "4px" }} {...props}>
              {children}
            </code>
          );
        },
      }}
      />
    }
    {
      params!="Quick" &&
    <div className="flex justify-center mt-6">
    <button
      className="bg-green-500 w-full text-white px-8 py-3 rounded-lg font-semibold hover:bg-green-600 transition-all duration-300 shadow-md"
      onClick={saveReport}
      > 
    <p> Save Report </p>    
    </button>
  </div> 
    }


      </div>}
      

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0  bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-[#0f172a] rounded-lg shadow-lg p-6 w-full max-w-md">
            <h2 className="text-xl font-bold text-white mb-4">System  Prompt</h2>
            <textarea
              className="w-full h-32 p-3 bg-slate-800 text-black border border-gray-700 rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your prompt here..."
              value={promptText}
              onChange={(e) => setPromptText(e.target.value)}
            ></textarea> 
            <div className="flex justify-end space-x-4">
              <button 
                  className={`px-4 py-2 
                    ${loading ? 'cursor-not-allowed' : 'hover:bg-gray-400'} 
                    bg-gray-300 text-gray-700 rounded-lg`} 
                disabled={loading} 
                onClick={handleModalClose} 
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                onClick={handleGenerateNote} 
                disabled={toastActive}
              > 
 
              <p>Generate</p>
          
                
              </button>
            </div>
          </div>
        </div>
      )} 
      {isOpen && (
        <div className="fixed inset-0  bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-[#0f172a] rounded-lg shadow-lg p-6 w-full max-w-md">
            <h2 className="text-xl font-bold text-white mb-4">Update  Prompt</h2>
            <textarea
              className="w-full h-32 p-3 bg-slate-800 text-black border border-gray-700 rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your prompt here..."
              value={changeText}
              onChange={(e) => setchangeText(e.target.value)}
            ></textarea> 
            <div className="flex justify-end space-x-4">
              <button 
                  className={`px-4 py-2 
                    ${updating ? 'cursor-not-allowed' : 'hover:bg-gray-400'} 
                    bg-gray-300 text-gray-700 rounded-lg`} 
                disabled={updating} 
                onClick={()=>setOpen(false)} 
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                onClick={handleRevise} 
                disabled={toastActive}
              > 
               {updating ? ( 
              <div className="flex justify-center">
              <Loader/>  
              </div>
            ) : ( 
 
              <p>Update</p>
            )
            }
                
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportGenerator;
