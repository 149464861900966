// import React from "react";

// // hooks
// import { useState, useRef, useCallback } from "react"
// import { Mic, Pause, Play, Square, Loader2  ,RotateCcw} from "lucide-react"

// //icons

// //Platform deetect
// import { isIos } from "environment";

// // network call
// import axios from "axios";

// //Toast
// import { ToastContainer, toast } from "react-toastify"

// //Server urls
// import { demoURL, Node_API_URL } from "../client";


// const AmeriCare = () => {

//   //states
//   const [recording, setRecording] = useState(false);
//   const [currentStep, setCurrentStep] = useState(0);
//   const [loading, setLoading] = useState(false);
//   const [paused, setPaused] = useState(false)
//   const [audioUrl, setAudioUrl] = useState(null) 
//   const [audioBlob, setAudioBlob] = useState(null)

//   const mediaRecorder = useRef(null)
//   const audioChunks = useRef([])

//   //Refs
//   const chunksRef = useRef([]);

//   //Array
//   const steps = [
//     "Step 1 Personal Information",
//     "Step 2 Insurance Information",
//     "Step 3 Medical & Family History",
//     "Step 4 Reason for Visit & Symptoms",
//     "Step 5 Social History",
//     "Step 6 Review of Systems",
//     "Step 7 Additional Health & Lifestyle",
//     "Step 8 Biometric Measures",
//     "Step 9 Additional Comments",
//   ];

//   //formData
//   const [formData, setFormData] = useState({
//     //part1 ----------
//     firstName: "",
//     middleName: "",
//     lastName: "",
//     dob: "",
//     gender: "",
//     homePhone: "",
//     mobilePhone: "",
//     email: "",
//     address1: "",
//     address2: "",
//     city: "",
//     state: "",
//     zip: "",
//     preferredContact: "",
//     //part2 ----------
//     insuranceProvider: "",
//     policyMemberId: "",
//     groupNumber: "",
//     policyHolderName: "",
//     policyHolderDob: "",
//     primaryCarePhysician: "",
//     //part3 ----------
//     currentMedications: "",
//     allergies: "",
//     chronicConditions: "",
//     pastSurgeries: "",
//     familyHistory: "",
//     //part4 ----------
//     reasonForVisit: "",
//     symptomsDetail: "",
//     symptomsDuration: "",
//     symptomsSeverity: "",
//     experiencedBefore: "",
//     symptomsBeforeWhen: "",
//     symptomsAggravators: "",
//     //part5 ----------
//     occupation: "",
//     livingArrangement: "",
//     tobaccoUse: "",
//     alcoholUse: "",
//     recreationalDrugs: "",
//     //part6 ----------
//     weightLossFeverFatigue:'', 
//     chestPainPalpitationsLegSwelling:'', 
//     coughShortnessBreathWheezing:'', 
//     nauseaVomitingDiarrheaConstipation:'', 
//     jointPainMuscleAchesWeakness:'', 
//     headachesDizzinessNumbness:'',
//     //part7 ----------
//     physicalActivity: "",
//     nutrition: "",
//     seatBeltUse: "",
//     depression: "",
//     anxiety: "",
//     stress: "",
//     socialEmotionalSupport: "",
//     pain: "",
//     generalHealth: "",
//     activitiesOfDailyLiving: "",
//     sleep: "",
//     //part8 ----------
//     bloodPressure: "",
//     cholesterol: "",
//     bloodGlucose: "",
//     height: "",
//     weight: "",
//     waistCircumference: "",
//     //part9 ----------
//     comment: "",
//   });

//   //onChange
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prev) => ({ ...prev, [name]: value }));
//   };
//   const handleReset = () => { 
//     setAudioBlob(null); 
//     setAudioUrl(null); 
//     setPaused(false)
//     setRecording(false)
//   }
//   //Next Container
//   const handleNext = (e) => { 
//     e.preventDefault();
//     handleReset()
//     setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1))
//   };

//   //Previous Container
//   const handlePrevious = (e) => {
//     e.preventDefault();
//     handleReset()
//     setCurrentStep((prev) => Math.max(prev - 1, 0));
//   };

//   //Submit
//   const handleSubmit = async  () => {
//     console.log('hitting');
//     console.log(formData);
//     setLoading(true)
//     try{
//       const response = await axios.post(`${Node_API_URL}/api/post/ameriarePatientDocument`,{formData,doctorMail:'workmbmb648@gmail.com'}) 
//       console.log('res', response);
//       if(response.data.success) {
//         setFormData({})
//         toast.info(response.data.msg);
//       }else{
//         toast.info(response.data.msg);
//       }
//     }catch(e){
//       toast.error(`Form submission failed`);
//     }finally{
//       setLoading(false)
//     }

    
//   };
  
 

  

//   const startRecording = useCallback(() => {
//     navigator.mediaDevices
//       .getUserMedia({ audio: true })
//       .then((stream) => {
//         mediaRecorder.current = new MediaRecorder(stream)
//         mediaRecorder.current.ondataavailable = (event) => {
//           audioChunks.current.push(event.data)
//         }
//         mediaRecorder.current.onstop = () => {
//           const blob = new Blob(audioChunks.current, {
//             type: isIos ? "audio/mp4" : "audio/webm",
//           })
//           setAudioBlob(blob)
//           audioChunks.current = []
//         }
//         mediaRecorder.current.start()
//         setRecording(true)
//         setPaused(false)
//       })
//       .catch((error) => console.error("Error accessing microphone:", error))
//   }, [])

//   const stopRecording = useCallback(() => {
//     if (mediaRecorder.current) {
//       mediaRecorder.current.stop()
//       setRecording(false)
//       setPaused(false)
//     }
//   }, [])

//   const pauseRecording = useCallback(() => {
//     if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
//       mediaRecorder.current.pause()
//       setPaused(true)
//     }
//   }, [])

//   const resumeRecording = useCallback(() => {
//     if (mediaRecorder.current && mediaRecorder.current.state === "paused") {
//       mediaRecorder.current.resume()
//       setPaused(false)
//     }
//   }, [])

//   const toggleRecording = useCallback(() => {
//     if (!recording) {
//       startRecording()
//     } else if (paused) {
//       resumeRecording()
//     } else {
//       pauseRecording()
//     }
//   }, [recording, paused, startRecording, resumeRecording, pauseRecording])
 

 
//   //Transcribe audio into text
//   const sendAudio = async (e) => {
//     e.preventDefault();
//    setLoading(true);
   
//     const bodyData = new FormData();
//     console.log('blob',audioBlob);
//     bodyData.append("stepIndex",currentStep); 
//     bodyData.append('file', audioBlob); 


//     try {
//       setLoading(true);     
//        const response = await axios.post(`${demoURL}/openai/voiceIntake2.0`,bodyData); 

//       console.log('response',response);
//       if (response.data.success === true) {
//         chunksRef.current = [];
//         let responseData = response.data.data;
//         setFormData((prevFormData) => ({
//           ...prevFormData,
//           ...Object.keys(prevFormData).reduce((acc, key) => {
//             if (responseData.hasOwnProperty(key)) {
//               acc[key] = responseData[key];
//             }
//             return acc;
//           }, {}),
//         }));

//         toast.info(
//           "Check the inbox for each question. If there is any incorrect information, edit it manually."
//         );
//       } else {
//         toast.error(`Error: ${response.data.msg}`);
//       }
//     } catch (e) {
//       toast.error(`Error in processing information`);
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Questions 
//   const renderStep = () => {
//     switch (currentStep) {
//       case 0:
//         return (
//           <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//             {[
//               "firstName",
//               "middleName",
//               "lastName",
//               "dob",
//               "gender",
//               "homePhone",
//               "mobilePhone",
//               "email",
//               "address1",
//               "address2",
//               "city",
//               "state",
//               "zip",
//               "preferredContact",
//             ].map((field) => (
//               <div key={field} className="space-y-1">
//                 <label className="text-gray-600 dark:text-gray-300">
//                   {field
//                     .replace(/([A-Z])/g, " $1")
//                     .replace(/^./, (str) => str.toUpperCase())}
//                 </label>
//                 {field === "gender" ? (
//                   <SelectField
//                   name={field}
//                     value={formData[field]}
//                     onChange={handleChange}
//                   options={["Male", "Female"]}
//                 />
//                 ) : field === "preferredContact" ? (
//                   <SelectField
//                   name={field}
//                     value={formData[field]}
//                     onChange={handleChange}
//                     options={["Home Phone", "Mobile Phone", "Email"]}
//                 />
//                 ) : (
//                   <InputField name={field} value={formData[field]} onChange={handleChange} />
//                 )}
//               </div>
//             ))}
//           </div>
//         );
//       case 1:
//         return (
//             <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//             {[
//               "insuranceProvider",
//               "policyMemberId",
//               "groupNumber",
//               "policyHolderName",
//               "policyHolderDob",
//               "primaryCarePhysician",
//             ].map((field) => (
//               <div key={field} className="space-y-1">
//                 <label className="text-gray-600 dark:text-gray-300">
//                   {field
//                     .replace(/([A-Z])/g, " $1")
//                     .replace(/^./, (str) => str.toUpperCase())}
//                 </label>
//                 <InputField name={field} value={formData[field]} onChange={handleChange} />
//               </div>
//             ))}
//           </div>
//         );
//       case 2:
//         return (
//             <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//             {[
//               "currentMedications",
//               "allergies",
//               "chronicConditions",
//               "pastSurgeries",
//               "familyHistory",
//             ].map((field) => (
//               <div key={field} className="space-y-1">
//                 <label className="text-gray-600 dark:text-gray-300">
//                   {field
//                     .replace(/([A-Z])/g, " $1")
//                     .replace(/^./, (str) => str.toUpperCase())}
//                 </label>
//                 <InputField name={field} value={formData[field]} onChange={handleChange} />
//               </div>
//             ))}
//           </div>
//         );
//       case 3:
//         return (
//           <div className="grid grid-cols-1 md:grid-cols-2 gap-4">   
         
//             <div className="space-y-1">
//               <label className="text-gray-600 dark:text-gray-300">
//                 What brings you in today?
//               </label>
//               <InputField name={'reasonForVisit'} value={formData.reasonForVisit} onChange={handleChange} />
//             </div>
//             <div className="space-y-1">
//               <label className="text-gray-600 dark:text-gray-300">
//                 Describe your symptoms in detail.
//               </label>
//               <InputField name={'symptomsDetail'} value={formData.symptomsDetail} onChange={handleChange} />
//             </div>
//             <div className="space-y-1">
//               <label className="text-gray-600 dark:text-gray-300">
//                 How long have you had these symptoms?
//               </label>
//               <InputField name={'symptomsDuration'} value={formData.symptomsDuration} onChange={handleChange} />
//             </div>
//             <div className="space-y-1">
//               <label className="text-gray-600 dark:text-gray-300">
//                 How long have you had these symptoms?
//               </label>
//               <InputField name={'symptomsSeverity'} value={formData.symptomsSeverity} onChange={handleChange} />
//             </div>
//             <div className="space-y-1 mt-1">
//               <label className="text-gray-600 dark:text-gray-300">
//                 Have you experienced these symptoms before? (Yes/No)
//               </label>
//                   <SelectField
//                   name={'experiencedBefore'}
//                     value={formData.experiencedBefore}
//                     onChange={handleChange}
//                     options={["Yes", "No"]}
//                 />

//             </div>
            
//             <div className="space-y-1">
//               <label className="text-gray-600 dark:text-gray-300">
//                 Anything that makes the symptoms better or worse?
//               </label>
//               <InputField name={'symptomsBeforeWhen'} value={formData.symptomsBeforeWhen} onChange={handleChange} />
//             </div>
//             <div className="space-y-1">
//               <label className="text-gray-600 dark:text-gray-300">
//                 If YES, when?
//               </label>
//               <InputField name={'symptomsAggravators'} value={formData.symptomsAggravators} onChange={handleChange} />
//             </div>
//           </div>
//         );
//       case 4:
//         return (
//             <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//             {[
//              "occupation",
//              "livingArrangement",
//              "tobaccoUse",
//              "alcoholUse",
//              "recreationalDrugs",
//             ].map((field) => (
//               <div key={field} className="space-y-1">
//                 <label className="text-gray-600 dark:text-gray-300">
//                   {field
//                     .replace(/([A-Z])/g, " $1")
//                     .replace(/^./, (str) => str.toUpperCase())}
//                 </label>
//                 {
//                   field === "tobaccoUse" || field === "alcoholUse" || field === "recreationalDrugs"   ? 
//                   <SelectField
//                   name={field}
//                     value={formData[field]}
//                     onChange={handleChange}
//                     options={["Yes", "No"]}
//                 />
//                   :
//                   <InputField name={field} value={formData[field]} onChange={handleChange} />
//                 }
//               </div>
//             ))}
//           </div>
//         );
//       case 5:
//         return (
//           <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//             <div className="space-y-1">
//               <label className="text-gray-600 dark:text-gray-300">
//                 Weight Loss, Fever, Fatigue
//               </label>
//               <InputField name={'weightLossFeverFatigue'} value={formData.weightLossFeverFatigue} onChange={handleChange} />
//             </div>
//             <div className="space-y-1">
//               <label className="text-gray-600 dark:text-gray-300">
//                 Chest Pain, Palpitations, Leg Swelling
//               </label>
//               <InputField name={'chestPainPalpitationsLegSwelling'} value={formData.chestPainPalpitationsLegSwelling} onChange={handleChange} />
//             </div>
//             <div className="space-y-1">
//               <label className="text-gray-600 dark:text-gray-300">
//                 Cough, Shortness of Breath, Wheezing
//               </label>
//               <InputField name={'coughShortnessBreathWheezing'} value={formData.coughShortnessBreathWheezing} onChange={handleChange} />
//             </div>
//             <div className="space-y-1">
//               <label className="text-gray-600 dark:text-gray-300">
//                 Nausea, Vomiting, Diarrhea, Constipation
//               </label>
//               <InputField name={'nauseaVomitingDiarrheaConstipation'} value={formData.nauseaVomitingDiarrheaConstipation} onChange={handleChange} />
//             </div>
//             <div className="space-y-1">
//               <label className="text-gray-600 dark:text-gray-300">
//                 Joint Pain, Muscle Aches, Weakness
//               </label>
//               <InputField name={'jointPainMuscleAchesWeakness'} value={formData.jointPainMuscleAchesWeakness} onChange={handleChange} />
//             </div>
//             <div className="space-y-1">
//               <label className="text-gray-600 dark:text-gray-300">
//                 Headaches, Dizziness, Numbness
//               </label>
//               <InputField name={'headachesDizzinessNumbness'} value={formData.headachesDizzinessNumbness} onChange={handleChange} />
//             </div>
//           </div>
//         );
//       case 6:
//         return (
//             <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//             {[
//               "physicalActivity",
//               "nutrition",
//               "seatBeltUse",
//               "depression",
//               "anxiety",
//               "stress",
//               "socialEmotionalSupport",
//               "pain",
//               "generalHealth",
//               "activitiesOfDailyLiving",
//               "sleep"
//             ].map((field) => (
//               <div key={field} className="space-y-1">
//                 <label className="text-gray-600 dark:text-gray-300">
//                   {field
//                     .replace(/([A-Z])/g, " $1")
//                     .replace(/^./, (str) => str.toUpperCase())}
//                 </label>
//                 {
//                   field === "seatBeltUse"  ? 
//                   <SelectField
//                   name={field}
//                     value={formData[field]}
//                     onChange={handleChange}
//                   options={["Male", "Female"]}
//                 />
//                   :
//                   <InputField name={field} value={formData[field]} onChange={handleChange} />
//                 }
//               </div>
//             ))}
//           </div>
//         );
//       case 7:
//         return (
//           <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//             {[
//               "bloodPressure",
//               "cholesterol",
//               "bloodGlucose",
//               "height",
//               "weight",
//               "waistCircumference",
//             ].map((field) => (
//               <div key={field} className="space-y-1">
//                 <label className="text-gray-600 dark:text-gray-300">
//                   {field
//                     .replace(/([A-Z])/g, " $1")
//                     .replace(/^./, (str) => str.toUpperCase())}
//                 </label>
//                 <InputField name={field} value={formData[field]} onChange={handleChange} />
//               </div>
//             ))}
//           </div>
//         );
//       case 8:
//         return (
//           <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
//             <div className="space-y-1">
//               <label className="text-gray-600 dark:text-gray-300">
//                 Comment
//               </label>
//               <textarea
//                 name="comment"
//                 value={formData.comment}
//                 onChange={handleChange}
//                 rows={5}
//                 className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-600 dark:text-white"
//               />
//             </div>
//           </div>
//         );
//       default:
//       //   return <div className="text-center">Coming soon...</div>;
    
//       }
//   };



//   return (
//     <>
//       <ToastContainer />
//       <div className="bg-gradient-to-br from-blue-50 to-indigo-100 dark:from-gray-900 dark:to-slate-800 flex justify-center items-center min-h-screen p-4"> 
//       {loading && 
//             <div className="fixed inset-0 bg-black/50 bg-opacity-50 flex justify-center items-center z-50">
//       <div className="animate-spin"> 
        
//         <Loader2 className="w-12 h-12 text-white" />
//       </div>
//     </div>
//           }
//         <div className="w-full max-w-2xl bg-white dark:bg-gray-800 p-8 rounded-2xl shadow-2xl transition-all duration-300 ease-in-out hover:shadow-3xl">
//           <h1 className="text-3xl font-bold text-center text-gray-800 dark:text-white mb-6">
//             Voice Intake Form
//           </h1>
//           <div className="text-center mb-8">
//           <div className="flex justify-center mb-6">
//           <button
//             onClick={toggleRecording}
//             className={`p-4 rounded-full transition-all duration-300 ease-in-out transform hover:scale-110 focus:outline-none focus:ring-4 ${
//               recording
//                 ? paused
//                   ? "bg-green-500 hover:bg-green-600 focus:ring-green-300"
//                   : "bg-red-500 hover:bg-red-600 focus:ring-red-300"
//                 : "bg-blue-500 hover:bg-blue-600 focus:ring-blue-300"
//             }`}
//           >
//             {recording ? (
//               paused ? (
//                 <Play size={32} className="text-white" />
//               ) : (
//                 <Pause size={32} className="text-white" />
//               )
//             ) : (
//               <Mic size={32} className="text-white" />
//             )}
//           </button>
//         </div>

//         {recording &&  (
//           <div className="flex justify-center mb-6">
//             <button
//               onClick={stopRecording}
//               className="p-3 w-[100px] flex flex-col gap-1 items-center justify-center bg-gray-500 hover:bg-gray-600 text-white rounded-md transition-all duration-300 ease-in-out transform hover:scale-110 focus:outline-none focus:ring-4 focus:ring-gray-300"
//             >
//               <Square size={16} /> 
//               Save  
//             </button>
//           </div> 
//         )}     
//          {audioBlob && (
//           <div className="flex justify-center mb-6">
//             <button
//               onClick={handleReset}
//               className="p-3  w-[100px] flex flex-col gap-1 items-center justify-center bg-red-500 hover:bg-red-600 text-white rounded-md transition-all duration-300 ease-in-out transform hover:scale-110 focus:outline-none focus:ring-4 focus:ring-gray-300"
//             >
//               <RotateCcw size={16} /> 
//               Reset  
//             </button>
//           </div>
//         )}    
      
      
        
//           </div> 


//           <h2 className="text-xl font-semibold text-center text-gray-700 dark:text-gray-300 mb-6">
//             {steps[currentStep]}
//           </h2>
//           <div className="space-y-8">
//             {renderStep()}
//             <div className="flex justify-between items-center">
//               {currentStep > 0 && (
//                 <Button onClick={handlePrevious} variant="secondary">
//                   Previous
//                 </Button>
//               )}
//             <Button
//   onClick={sendAudio}
//   variant="primary"
//   disabled={audioBlob == null}
//   className={audioBlob == null ? "cursor-not-allowed px-6 py-3 rounded-lg font-semibold transition-all duration-300 ease-in-out  bg-gray-600  text-white" : "px-6 py-3 rounded-lg font-semibold transition-all duration-300 ease-in-out focus:outline-none focus:ring-4 bg-blue-600 hover:bg-blue-700 text-white  focus:ring-blue-300"}
// >
//   Proceed
// </Button>
//               {currentStep < steps.length - 1 ? (
//                 <Button
//                   onClick={currentStep === steps.length - 1 ? handleSubmit : handleNext}
//                   variant="primary"
//                 >
//                   Next
//                 </Button>
//               ) : (
//                 <Button onClick={handleSubmit} variant="success">Submit</Button>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// const Button = ({ children, variant = 'primary', ...props }) => {
//   const baseStyle = "px-6 py-3 rounded-lg font-semibold transition-all duration-300 ease-in-out focus:outline-none focus:ring-4";
//   const variants = {
//     primary: "bg-blue-600 hover:bg-blue-700 text-white focus:ring-blue-300",
//     secondary: "bg-gray-500 hover:bg-gray-600 text-white focus:ring-gray-300",
//     success: "bg-green-600 hover:bg-green-700 text-white focus:ring-green-300",
//   };

//   return (
//     <button className={`${baseStyle} ${variants[variant]}`} {...props}>
//       {children}
//     </button>
//   );
// };

// const InputField = ({ name, value, onChange, className = "", ...props }) => {
//   return (
//     <input
//       type="text"
//       name={name}
//       value={value}
//       onChange={onChange}
//       className={`w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white transition-all duration-300 ease-in-out ${className}`}
//       {...props}
//     />
//   );
// };

// const SelectField = ({ name, value, onChange, options }) => {
//   return (
//     <select
//       name={name}
//       value={value}
//       onChange={onChange}
//       className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white appearance-none bg-white dark:bg-gray-700 transition-all duration-300 ease-in-out"
//     >
//       <option value="">Select</option>
//       {options.map((option, index) => (
//         <option key={index} value={option}>
//           {option}
//         </option>
//       ))}
//     </select>
//   );
// };

// export default AmeriCare;



// hooks
import { useState, useRef, useCallback } from "react"
import { Mic, Pause, Play, Square, Loader2, RotateCcw, Info } from "lucide-react"

//icons

//Platform deetect
import { isIos } from "environment"

// network call
import axios from "axios"

//Toast
import { ToastContainer, toast } from "react-toastify"

//Server urls
import { demoURL, Node_API_URL } from "../client"

const AmeriCare = () => {
  //states
  const [recording, setRecording] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const [paused, setPaused] = useState(false)
  const [audioUrl, setAudioUrl] = useState(null)
  const [audioBlob, setAudioBlob] = useState(null)
  const [showInstructions, setShowInstructions] = useState(true)

  const mediaRecorder = useRef(null)
  const audioChunks = useRef([])

  //Refs
  const chunksRef = useRef([])

  //Array
  const steps = [
    "Step 1 Personal Information",
    "Step 2 Insurance Information",
    "Step 3 Medical & Family History",
    "Step 4 Reason for Visit & Symptoms",
    "Step 5 Social History",
    "Step 6 Review of Systems",
    "Step 7 Additional Health & Lifestyle",
    "Step 8 Biometric Measures",
    "Step 9 Additional Comments",
  ]

  //formData
  const [formData, setFormData] = useState({
    //part1 ----------
    firstName: "",
    middleName: "",
    lastName: "",
    dob: "",
    gender: "",
    homePhone: "",
    mobilePhone: "",
    email: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    preferredContact: "",
    //part2 ----------
    insuranceProvider: "",
    policyMemberId: "",
    groupNumber: "",
    policyHolderName: "",
    policyHolderDob: "",
    primaryCarePhysician: "",
    //part3 ----------
    currentMedications: "",
    allergies: "",
    chronicConditions: "",
    pastSurgeries: "",
    familyHistory: "",
    //part4 ----------
    reasonForVisit: "",
    symptomsDetail: "",
    symptomsDuration: "",
    symptomsSeverity: "",
    experiencedBefore: "",
    symptomsBeforeWhen: "",
    symptomsAggravators: "",
    //part5 ----------
    occupation: "",
    livingArrangement: "",
    tobaccoUse: "",
    alcoholUse: "",
    recreationalDrugs: "",
    //part6 ----------
    weightLossFeverFatigue: "",
    chestPainPalpitationsLegSwelling: "",
    coughShortnessBreathWheezing: "",
    nauseaVomitingDiarrheaConstipation: "",
    jointPainMuscleAchesWeakness: "",
    headachesDizzinessNumbness: "",
    //part7 ----------
    physicalActivity: "",
    nutrition: "",
    seatBeltUse: "",
    depression: "",
    anxiety: "",
    stress: "",
    socialEmotionalSupport: "",
    pain: "",
    generalHealth: "",
    activitiesOfDailyLiving: "",
    sleep: "",
    //part8 ----------
    bloodPressure: "",
    cholesterol: "",
    bloodGlucose: "",
    height: "",
    weight: "",
    waistCircumference: "",
    //part9 ----------
    comment: "",
  })

  //onChange
  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({ ...prev, [name]: value }))
  }
  const handleReset = () => {
    setAudioBlob(null)
    setAudioUrl(null)
    setPaused(false)
    setRecording(false)
  }
  //Next Container
  const handleNext = (e) => {
    e.preventDefault()
    handleReset()
    setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1))
  }

  //Previous Container
  const handlePrevious = (e) => {
    e.preventDefault()
    handleReset()
    setCurrentStep((prev) => Math.max(prev - 1, 0))
  }

  //Submit
  const handleSubmit = async () => {
    console.log("hitting")
    console.log(formData)
    setLoading(true)
    try {
      const response = await axios.post(`${Node_API_URL}/api/post/ameriarePatientDocument`, {
        formData,
        doctorMail: "workmbmb648@gmail.com",
      })
      console.log("res", response)
      if (response.data.success) {
        setFormData({})
        toast.info(response.data.msg)
      } else {
        toast.info(response.data.msg)
      }
    } catch (e) {
      toast.error(`Form submission failed`)
    } finally {
      setLoading(false)
    }
  }

  const startRecording = useCallback(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorder.current = new MediaRecorder(stream)
        mediaRecorder.current.ondataavailable = (event) => {
          audioChunks.current.push(event.data)
        }
        mediaRecorder.current.onstop = () => {
          const blob = new Blob(audioChunks.current, {
            type: isIos ? "audio/mp4" : "audio/webm",
          })
          setAudioBlob(blob)
          audioChunks.current = []
        }
        mediaRecorder.current.start()
        setRecording(true)
        setPaused(false)
      })
      .catch((error) => console.error("Error accessing microphone:", error))
  }, [])

  const stopRecording = useCallback(() => {
    if (mediaRecorder.current) {
      mediaRecorder.current.stop()
      setRecording(false)
      setPaused(false)
    }
  }, [])

  const pauseRecording = useCallback(() => {
    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      mediaRecorder.current.pause()
      setPaused(true)
    }
  }, [])

  const resumeRecording = useCallback(() => {
    if (mediaRecorder.current && mediaRecorder.current.state === "paused") {
      mediaRecorder.current.resume()
      setPaused(false)
    }
  }, [])

  const toggleRecording = useCallback(() => {
    if (!recording) {
      startRecording()
    } else if (paused) {
      resumeRecording()
    } else {
      pauseRecording()
    }
  }, [recording, paused, startRecording, resumeRecording, pauseRecording])

  //Transcribe audio into text
  const sendAudio = async (e) => {
    e.preventDefault()
    setLoading(true)

    const bodyData = new FormData()
    console.log("blob", audioBlob)
    bodyData.append("stepIndex", currentStep)
    bodyData.append("file", audioBlob)

    try {
      setLoading(true)
      const response = await axios.post(`${demoURL}/openai/voiceIntake2.0`, bodyData)

      console.log("response", response)
      if (response.data.success === true) {
        chunksRef.current = []
        const responseData = response.data.data
        setFormData((prevFormData) => ({
          ...prevFormData,
          ...Object.keys(prevFormData).reduce((acc, key) => {
            if (responseData.hasOwnProperty(key)) {
              acc[key] = responseData[key]
            }
            return acc
          }, {}),
        }))

        toast.info("Check the inbox for each question. If there is any incorrect information, edit it manually.")
      } else {
        toast.error(`Error: ${response.data.msg}`)
      }
    } catch (e) {
      toast.error(`Error in processing information`)
    } finally {
      setLoading(false)
    }
  }

  // Questions
  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {[
              "firstName",
              "middleName",
              "lastName",
              "dob",
              "gender",
              "homePhone",
              "mobilePhone",
              "email",
              "address1",
              "address2",
              "city",
              "state",
              "zip",
              "preferredContact",
            ].map((field) => (
              <div key={field} className="space-y-1">
                <label className="text-gray-600 dark:text-gray-300">
                  {field.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase())}
                </label>
                {field === "gender" ? (
                  <SelectField
                    name={field}
                    value={formData[field]}
                    onChange={handleChange}
                    options={["Male", "Female"]}
                  />
                ) : field === "preferredContact" ? (
                  <SelectField
                    name={field}
                    value={formData[field]}
                    onChange={handleChange}
                    options={["Home Phone", "Mobile Phone", "Email"]}
                  />
                ) : (
                  <InputField name={field} value={formData[field]} onChange={handleChange} />
                )}
              </div>
            ))}
          </div>
        )
      case 1:
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {[
              "insuranceProvider",
              "policyMemberId",
              "groupNumber",
              "policyHolderName",
              "policyHolderDob",
              "primaryCarePhysician",
            ].map((field) => (
              <div key={field} className="space-y-1">
                <label className="text-gray-600 dark:text-gray-300">
                  {field.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase())}
                </label>
                <InputField name={field} value={formData[field]} onChange={handleChange} />
              </div>
            ))}
          </div>
        )
      case 2:
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {["currentMedications", "allergies", "chronicConditions", "pastSurgeries", "familyHistory"].map((field) => (
              <div key={field} className="space-y-1">
                <label className="text-gray-600 dark:text-gray-300">
                  {field.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase())}
                </label>
                <InputField name={field} value={formData[field]} onChange={handleChange} />
              </div>
            ))}
          </div>
        )
      case 3:
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="space-y-1">
              <label className="text-gray-600 dark:text-gray-300">What brings you in today?</label>
              <InputField name={"reasonForVisit"} value={formData.reasonForVisit} onChange={handleChange} />
            </div>
            <div className="space-y-1">
              <label className="text-gray-600 dark:text-gray-300">Describe your symptoms in detail.</label>
              <InputField name={"symptomsDetail"} value={formData.symptomsDetail} onChange={handleChange} />
            </div>
            <div className="space-y-1">
              <label className="text-gray-600 dark:text-gray-300">How long have you had these symptoms?</label>
              <InputField name={"symptomsDuration"} value={formData.symptomsDuration} onChange={handleChange} />
            </div>
            <div className="space-y-1">
              <label className="text-gray-600 dark:text-gray-300">How long have you had these symptoms?</label>
              <InputField name={"symptomsSeverity"} value={formData.symptomsSeverity} onChange={handleChange} />
            </div>
            <div className="space-y-1 mt-1">
              <label className="text-gray-600 dark:text-gray-300">
                Have you experienced these symptoms before? (Yes/No)
              </label>
              <SelectField
                name={"experiencedBefore"}
                value={formData.experiencedBefore}
                onChange={handleChange}
                options={["Yes", "No"]}
              />
            </div>

            <div className="space-y-1">
              <label className="text-gray-600 dark:text-gray-300">
                Anything that makes the symptoms better or worse?
              </label>
              <InputField name={"symptomsBeforeWhen"} value={formData.symptomsBeforeWhen} onChange={handleChange} />
            </div>
            <div className="space-y-1">
              <label className="text-gray-600 dark:text-gray-300">If YES, when?</label>
              <InputField name={"symptomsAggravators"} value={formData.symptomsAggravators} onChange={handleChange} />
            </div>
          </div>
        )
      case 4:
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {["occupation", "livingArrangement", "tobaccoUse", "alcoholUse", "recreationalDrugs"].map((field) => (
              <div key={field} className="space-y-1">
                <label className="text-gray-600 dark:text-gray-300">
                  {field.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase())}
                </label>
                {field === "tobaccoUse" || field === "alcoholUse" || field === "recreationalDrugs" ? (
                  <SelectField name={field} value={formData[field]} onChange={handleChange} options={["Yes", "No"]} />
                ) : (
                  <InputField name={field} value={formData[field]} onChange={handleChange} />
                )}
              </div>
            ))}
          </div>
        )
      case 5:
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="space-y-1">
              <label className="text-gray-600 dark:text-gray-300">Weight Loss, Fever, Fatigue</label>
              <InputField
                name={"weightLossFeverFatigue"}
                value={formData.weightLossFeverFatigue}
                onChange={handleChange}
              />
            </div>
            <div className="space-y-1">
              <label className="text-gray-600 dark:text-gray-300">Chest Pain, Palpitations, Leg Swelling</label>
              <InputField
                name={"chestPainPalpitationsLegSwelling"}
                value={formData.chestPainPalpitationsLegSwelling}
                onChange={handleChange}
              />
            </div>
            <div className="space-y-1">
              <label className="text-gray-600 dark:text-gray-300">Cough, Shortness of Breath, Wheezing</label>
              <InputField
                name={"coughShortnessBreathWheezing"}
                value={formData.coughShortnessBreathWheezing}
                onChange={handleChange}
              />
            </div>
            <div className="space-y-1">
              <label className="text-gray-600 dark:text-gray-300">Nausea, Vomiting, Diarrhea, Constipation</label>
              <InputField
                name={"nauseaVomitingDiarrheaConstipation"}
                value={formData.nauseaVomitingDiarrheaConstipation}
                onChange={handleChange}
              />
            </div>
            <div className="space-y-1">
              <label className="text-gray-600 dark:text-gray-300">Joint Pain, Muscle Aches, Weakness</label>
              <InputField
                name={"jointPainMuscleAchesWeakness"}
                value={formData.jointPainMuscleAchesWeakness}
                onChange={handleChange}
              />
            </div>
            <div className="space-y-1">
              <label className="text-gray-600 dark:text-gray-300">Headaches, Dizziness, Numbness</label>
              <InputField
                name={"headachesDizzinessNumbness"}
                value={formData.headachesDizzinessNumbness}
                onChange={handleChange}
              />
            </div>
          </div>
        )
      case 6:
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {[
              "physicalActivity",
              "nutrition",
              "seatBeltUse",
              "depression",
              "anxiety",
              "stress",
              "socialEmotionalSupport",
              "pain",
              "generalHealth",
              "activitiesOfDailyLiving",
              "sleep",
            ].map((field) => (
              <div key={field} className="space-y-1">
                <label className="text-gray-600 dark:text-gray-300">
                  {field.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase())}
                </label>
                {field === "seatBeltUse" ? (
                  <SelectField
                    name={field}
                    value={formData[field]}
                    onChange={handleChange}
                    options={["Male", "Female"]}
                  />
                ) : (
                  <InputField name={field} value={formData[field]} onChange={handleChange} />
                )}
              </div>
            ))}
          </div>
        )
      case 7:
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {["bloodPressure", "cholesterol", "bloodGlucose", "height", "weight", "waistCircumference"].map((field) => (
              <div key={field} className="space-y-1">
                <label className="text-gray-600 dark:text-gray-300">
                  {field.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase())}
                </label>
                <InputField name={field} value={formData[field]} onChange={handleChange} />
              </div>
            ))}
          </div>
        )
      case 8:
        return (
          <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
            <div className="space-y-1">
              <label className="text-gray-600 dark:text-gray-300">Comment</label>
              <textarea
                name="comment"
                value={formData.comment}
                onChange={handleChange}
                rows={5}
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-600 dark:text-white"
              />
            </div>
          </div>
        )
      default:
      //   return <div className="text-center">Coming soon...</div>;
    }
  }

  return (
    <>
      <ToastContainer />
      <div className="bg-gradient-to-br from-blue-50 to-indigo-100 dark:from-gray-900 dark:to-slate-800 flex justify-center items-center min-h-screen p-4">
        {loading && (
          <div className="fixed inset-0 bg-black/50 bg-opacity-50 flex justify-center items-center z-50">
            <div className="animate-spin">
              <Loader2 className="w-12 h-12 text-white" />
            </div>
          </div>
        )}
        <div className="w-full max-w-2xl bg-white dark:bg-gray-800 p-8 rounded-2xl shadow-2xl transition-all duration-300 ease-in-out hover:shadow-3xl">
          <h1 className="text-3xl font-bold text-center text-gray-800 dark:text-white mb-6">Voice Intake Form</h1>

          {/* Instructions Panel */}
          {showInstructions && (
            <div className="mb-6 p-4 bg-blue-50 dark:bg-gray-700 rounded-lg border border-blue-200 dark:border-gray-600">
              <div className="flex justify-between items-center mb-2">
                <h3 className="text-lg font-semibold flex items-center gap-2 text-blue-700 dark:text-blue-300">
                  <Info size={18} /> How to use voice recording
                </h3>
                <button
                  onClick={() => setShowInstructions(false)}
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                >
                  ✕
                </button>
              </div>
              <ol className="list-decimal pl-5 space-y-1 text-sm text-gray-700 dark:text-gray-300">
                <li>Click on the microphone button to start recording</li>
                <li>Speak clearly to describe your information for this section</li>
                <li>Click the pause button if you need to pause recording</li>
                <li>Click the save button when you're finished recording</li>
                <li>Click "Proceed" and wait for the form to automatically fill</li>
                <li>If you don't like the results, click "Reset" and record again</li>
              </ol>
              <p className="mt-2 text-xs text-red-600 dark:text-red-400 font-medium">
                Note: If you navigate to another section using the Next/Previous buttons, your current audio recording will
                be deleted.
              </p>
            </div>
          )}

          <div className="text-center mb-8">
            <div className="flex justify-center mb-6">
              <button
                onClick={toggleRecording}
                className={`p-4 rounded-full transition-all duration-300 ease-in-out transform hover:scale-110 focus:outline-none focus:ring-4 ${
                  recording
                    ? paused
                      ? "bg-green-500 hover:bg-green-600 focus:ring-green-300"
                      : "bg-red-500 hover:bg-red-600 focus:ring-red-300"
                    : "bg-blue-500 hover:bg-blue-600 focus:ring-blue-300"
                }`}
              >
                {recording ? (
                  paused ? (
                    <Play size={32} className="text-white" />
                  ) : (
                    <Pause size={32} className="text-white" />
                  )
                ) : (
                  <Mic size={32} className="text-white" />
                )}
              </button>
            </div>

            {recording && (
              <div className="flex justify-center mb-6">
                <button
                  onClick={stopRecording}
                  className="p-3 w-[100px] flex flex-col gap-1 items-center justify-center bg-gray-500 hover:bg-gray-600 text-white rounded-md transition-all duration-300 ease-in-out transform hover:scale-110 focus:outline-none focus:ring-4 focus:ring-gray-300"
                >
                  <Square size={16} />
                  Save
                </button>
              </div>
            )}
            {audioBlob && (
              <div className="flex justify-center mb-6">
                <button
                  onClick={handleReset}
                  className="p-3  w-[100px] flex flex-col gap-1 items-center justify-center bg-red-500 hover:bg-red-600 text-white rounded-md transition-all duration-300 ease-in-out transform hover:scale-110 focus:outline-none focus:ring-4 focus:ring-gray-300"
                >
                  <RotateCcw size={16} />
                  Reset
                </button>
              </div>
            )}
          </div>

          <h2 className="text-xl font-semibold text-center text-gray-700 dark:text-gray-300 mb-6">
            {steps[currentStep]}
          </h2>
          <div className="space-y-8">
            {renderStep()}
            <div className="flex justify-between items-center">
              {currentStep > 0 && (
                <Button onClick={handlePrevious} variant="secondary">
                  Previous
                </Button>
              )}
              <Button
                onClick={sendAudio}
                variant="primary"
                disabled={audioBlob == null}
                className={
                  audioBlob == null
                    ? "cursor-not-allowed px-6 py-3 rounded-lg font-semibold transition-all duration-300 ease-in-out  bg-gray-600  text-white"
                    : "px-6 py-3 rounded-lg font-semibold transition-all duration-300 ease-in-out focus:outline-none focus:ring-4 bg-blue-600 hover:bg-blue-700 text-white  focus:ring-blue-300"
                }
              >
                Proceed
              </Button>
              {currentStep < steps.length - 1 ? (
                <Button onClick={currentStep === steps.length - 1 ? handleSubmit : handleNext} variant="primary">
                  Next
                </Button>
              ) : (
                <Button onClick={handleSubmit} variant="success">
                  Submit
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const Button = ({ children, variant = "primary", ...props }) => {
  const baseStyle =
    "px-6 py-3 rounded-lg font-semibold transition-all duration-300 ease-in-out focus:outline-none focus:ring-4"
  const variants = {
    primary: "bg-blue-600 hover:bg-blue-700 text-white focus:ring-blue-300",
    secondary: "bg-gray-500 hover:bg-gray-600 text-white focus:ring-gray-300",
    success: "bg-green-600 hover:bg-green-700 text-white focus:ring-green-300",
  }

  return (
    <button className={`${baseStyle} ${variants[variant]}`} {...props}>
      {children}
    </button>
  )
}

const InputField = ({ name, value, onChange, className = "", ...props }) => {
  return (
    <input
      type="text"
      name={name}
      value={value}
      onChange={onChange}
      className={`w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white transition-all duration-300 ease-in-out ${className}`}
      {...props}
    />
  )
}

const SelectField = ({ name, value, onChange, options }) => {
  return (
    <select
      name={name}
      value={value}
      onChange={onChange}
      className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white appearance-none bg-white dark:bg-gray-700 transition-all duration-300 ease-in-out"
    >
      <option value="">Select</option>
      {options.map((option, index) => (
        <option key={index} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

export default AmeriCare

